import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { useEffect } from "react"
import type { ComponentType } from "react"

// Create the store with a default value
const useStore = createStore({
    authenticated: false,
    loaded: false,
    session: null,
    user: null,
})

const isValidToken = (expires_at) => {
    const currentTime = Date.now()
    return (
        expires_at > currentTime &&
        expires_at.toString().length == currentTime.toString().length
    )
}

export function withCheckAuth(Component): ComponentType {
    return (props) => {
        const [store, setStore] = useStore()

        useEffect(() => {
            // Retrieve the access_token from localStorage
            const session = localStorage.getItem("nx-session")
            if (session) {
                const { access_token, expires_at } = JSON.parse(session)

                // If expiration date of token exists
                if (expires_at) {
                    if (isValidToken(expires_at)) {
                        setStore({ authenticated: true, loaded: true })
                    } else {
                        setStore({ authenticated: false, loaded: true })
                        console.error("Token has expired")
                    }
                } else {
                    setStore({ authenticated: false, loaded: true })
                    console.error("No expiry found in session")
                }

                /*
                // If we have a valid access_token, make the fetch call
                if (access_token) {
                    fetch(
                        "https://x8ki-letl-twmt.n7.xano.io/api:elH4PkAS/auth/me",
                        {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${access_token}`, // Add token to Authorization header
                            },
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            localStorage.setItem(
                                "nx-user",
                                JSON.stringify(data)
                            )
                            setStore({ authenticated: true, loaded: true })
                        })
                        .catch((error) => {
                            console.error("Error fetching data:", error)
                            setStore({ authenticated: false, loaded: true })
                        })
                } else {
                    console.error("No access token found in session.")
                    setStore({ authenticated: false, loaded: true })
                }
                */
            } else {
                console.error("No session found in localStorage")
                setStore({ authenticated: false, loaded: true })
            }
        }, [])
        return <Component {...props} />
    }
}

// Override for text elements to consume the fetched body content
export function applyFetchedContent(Component): ComponentType {
    return (props) => {
        const [store] = useStore() // Access the store

        return <Component {...props} text={store.bodyContent} />
    }
}

// Override for text elements to consume the fetched body content
export function isLoggedIn(Component): ComponentType {
    return (props) => {
        const [store] = useStore() // Access the store
        if (store.authenticated && store.loaded) {
            return <Component {...props} />
        } else {
            return null
        }
    }
}

export function isLoggedOut(Component): ComponentType {
    return (props) => {
        const [store] = useStore() // Access the store
        if (!store.authenticated && store.loaded) {
            return <Component {...props} />
        } else {
            return null
        }
    }
}

export function isLoading(Component): ComponentType {
    return (props) => {
        const [store] = useStore() // Access the store
        if (!store.loaded) {
            return <Component {...props} />
        } else {
            return null
        }
    }
}
